
import keys from 'keys';
import React from "react";
import { Card, CardBody, CardHeader, CardTitle, FormGroup, Input, Badge } from "reactstrap";
import Circle from 'components/markup/loading/Circle';
import { connect } from 'react-redux';
import ReactBSAlert from "react-bootstrap-sweetalert";

import _payment_event_logs from '_functions/payment_event_logs';

import _payments from '_functions/payments';
import _contacts from '_functions/contacts';
import { setViewingUser } from 'store/functions/auth/auth';
import { toggleStandardLoader } from 'store/functions/system/system'

class PaymentMethods extends React.Component {

    state = {
        payformUrl: null,
        account_vaults: null,
        loadedIframe: false,
        default_account_vault_id: this.props.viewing_user.default_account_vault_id,
        alert: null
    }

    fireAlert = (error, message) => {
        this.setState({
            alert: (
                <ReactBSAlert
                    success={error ? false : true}
                    danger={!error ? false : true}
                    title={error ? 'Error' : 'Success'}
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="success"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    { message }
                </ReactBSAlert>
            )
        });
    };

    hideAlert = () => this.setState({ alert: null });

    //send call to backend to remove the payment method
    onRemovePaymentMethod = async (account_vault_api_id) => {

        toggleStandardLoader(true)

        // remove card from fortispay
        const deleted = await _payments.account_vaults.delete(account_vault_api_id, {contact_id: this.props.viewing_user._id})
        // remove method from state
        if(deleted.success) {

            setViewingUser()

            this.fireAlert(false, 'Payment method removed.')
            this.refreshPaymentMethods()
            this.props.getPaymentMethods();

        } else {

            this.fireAlert(true, 'Something went wrong removing this payment method.')

        }

        toggleStandardLoader(false)

    }


    getPayformUrl = async () => {

        const payform = await _payments.createPayform(this.props.viewing_user._id)
        if(payform.success) { this.setState({payformUrl: payform.data}) }

    }

     //this function finds all payment methods associated with the user, will create contact if they don't exist
     refreshPaymentMethods = () => {
        return new Promise(async resolve => {

            const contact = this.props.viewing_user;
            const fortisContactExists = await _payments.contacts.findOrCreate(contact)

            if(fortisContactExists.success) { this.setState({account_vaults: fortisContactExists.data.account_vaults}) }

            resolve()

        })
    }

    // this function updates all internal recurrings fortispay recurrings and the contact default method
    onChangeDefaultMethod = async () => {

        const default_account_vault_id = this.state.default_account_vault_id

        // return if not default account id is given
        if(!default_account_vault_id) return

        toggleStandardLoader(true)

        // update all internal recurrings fortispay recurrings and the contact default method
        const updated = await _payments.account_vaults.updateDefaultVaults({
            contact_id: this.props.viewing_user._id,
            account_vault_id: default_account_vault_id
        })


        // send message to the viewing user
        if(updated.success) {

            this.fireAlert(false, 'Your default payment method has been updated.')
            setViewingUser()

            // create payment event log
            _payment_event_logs.create({
                value: `Contact updated their default card information`,
                contact: this.props.viewing_user._id,
                user: undefined
            })

        } else {

            this.fireAlert(true, 'Something went wrong updating your default payment method.')

        }

        toggleStandardLoader(false)


    }

     //when a new vault is added update state
     receiveZeamsterMessage = async (event) => {

        //if the origin is from zeamster add new card to state
        if (event.origin === keys.ZEAMSTER_ORIGIN) {

            //add payment method to state
            this.refreshPaymentMethods()
            this.props.getPaymentMethods();
            // set a timeout to refresh a new payform url
            setTimeout(async () => this.getPayformUrl(), 1500)

             // update all internal recurrings fortispay recurrings and the contact default method
            const updated = await _payments.account_vaults.updateDefaultVaults({
                contact_id: this.props.viewing_user._id,
                account_vault_id: parsedEvent.id
            })

            // contact does not have a default payment method set it
            const contact = this.props.viewing_user
            const parsedEvent = JSON.parse(event.data)

            // create payment event log
            _payment_event_logs.create({
                value: `Added the credit card: ${parsedEvent.first_six}...${parsedEvent.last_four} exp: ${parsedEvent.exp_date.substring(0, 2)}/${parsedEvent.exp_date.substring(2, 4)}`,
                contact: contact._id,
                user: undefined
            })

            setViewingUser()

            if(!contact.default_account_vault_id) {

                // set default card on contact
                await _contacts.update(contact._id, {default_account_vault_id: parsedEvent.id})
                // update the viewing user
                setViewingUser()

            }

        }

    }

    componentDidMount = async () => {

        window.addEventListener("message", this.receiveZeamsterMessage, false)

        await this.refreshPaymentMethods()
        this.props.getPaymentMethods();
        this.getPayformUrl()

    }

    componentWillUnmount = () => window.removeEventListener("message", this.receiveZeamsterMessage);

    render() {

        const { account_vaults, loadedIframe, payformUrl } = this.state
        const contact = this.props.viewing_user

        return (

            <div>

                {account_vaults ? (
                    account_vaults && account_vaults.length ? (

                        <>

                        <Card className="mb-4">

                            <CardHeader>
                                <CardTitle className="mb-0">My Payment Methods</CardTitle>
                                <p className="mb-0 text-sm">The card labeled "default" below is your primary card payment method. If you are not able to remove a card it means you have an active recurring subscription attached to that card.</p>
                            </CardHeader>

                            <div className="table-responsive">

                                {this.props.device.isMD ? (
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>Type</th>
                                                <th>Card #</th>
                                                <th>Expires</th>
                                                <td className="text-right">Actions</td>
                                            </tr>
                                        </thead>
    
                                        <tbody>
                                            {account_vaults.map(a => (
                                                <tr key={a.id}>
    
                                                    <td>
                                                        {_payments.cards.getName(a.account_type)}
                                                        {contact.default_account_vault_id === a.id ? (
                                                            <Badge className="ml-2" color="success" pill>Default</Badge>
                                                        ) : null}
                                                    </td>
                                                    <td>{a.first_six.slice(0,1)}......{a.last_four}</td>
                                                    <td>{a.exp_date.slice(0,2)}/{a.exp_date.slice(2,4)}</td>
    
                                                    <td className="text-right">
                                                        <button
                                                            disabled={a.has_recurring}
                                                            className="btn btn-danger btn-sm"
                                                            onClick={() => !a.has_recurring ? this.onRemovePaymentMethod(a.account_vault_api_id) : null}
                                                        >
                                                            <i className="fas fa-trash mr-2" />
                                                            Remove
                                                        </button>
                                                    </td>
    
                                                </tr>
                                            ))}
                                        </tbody>
    
                                    </table>
                                ) : (
                                    <table className="table">

                                        <thead>
                                            <tr>
                                                <th>Card</th>
                                                <td className="text-right">Actions</td>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {account_vaults.map(a => (
                                                <tr key={a.id}>

                                                    <td>
                                                        {_payments.cards.getName(a.account_type)}
                                                        {contact.default_account_vault_id === a.id ? (
                                                            <Badge className="ml-2" color="success" pill>Default</Badge>
                                                        ) : null}
                                                        <div>{a.first_six.slice(0,1)}......{a.last_four} - {a.exp_date.slice(0,2)}/{a.exp_date.slice(2,4)}</div>
                                                    </td>

                                                    <td className="text-right">
                                                        <button
                                                            style={{minWidth: 80}}
                                                            disabled={a.has_recurring}
                                                            className="btn btn-danger btn-sm"
                                                            onClick={() => !a.has_recurring ? this.onRemovePaymentMethod(a.account_vault_api_id) : null}
                                                        >
                                                            <i className="fas fa-trash mr-2" />
                                                            Remove
                                                        </button>
                                                    </td>

                                                </tr>
                                            ))}
                                        </tbody>

                                    </table>
                                )}
                              
                            </div>

                        </Card>

                        <Card className="mb-4">

                            <CardHeader>
                                <CardTitle className="mb-0">Set Default Payment Method</CardTitle>
                                <p className="mb-0 text-sm">
                                    By changing your default payment method all future recurring charges on your account will be sent to the card selected.
                                </p>
                            </CardHeader>

                            {account_vaults && account_vaults.length ? (

                                <CardBody>
                                    <FormGroup>
                                        <label className="form-control-label">Select Payment Method</label>
                                        <Input
                                            value={this.state.default_account_vault_id || ''}
                                            onChange={(e) => this.setState({default_account_vault_id: e.target.value})}
                                            type="select"
                                        >
                                            {account_vaults.map(a => (
                                                <option value={a.id} key={a.id}>
                                                    {_payments.cards.getName(a.account_type)}{' '}
                                                    {a.first_six.slice(0,1)}......{a.last_four}{' '}
                                                    Exp: {a.exp_date.slice(0,2)}/{a.exp_date.slice(2,4)}
                                                </option>
                                            ))}
                                        </Input>
                                    </FormGroup>

                                    <div className="text-right">
                                        <button
                                            onClick={contact.default_account_vault_id === this.state.default_account_vault_id ? null : this.onChangeDefaultMethod}
                                            className="btn btn-success"
                                            disabled={contact.default_account_vault_id === this.state.default_account_vault_id ? true : false}
                                        >
                                            Change Payment Method
                                        </button>
                                    </div>

                                </CardBody>

                            ) : (

                                <CardBody>
                                <p className="text-sm">
                                    <i className="fas fa-exclamation-triangle text-danger mr-2" /> You do not have any payment methods on file and therefore cannot change your default card.
                                    </p>
                                </CardBody>

                            )}

                        </Card>

                        </>
                    ) : (

                        <div className="alert alert-danger">There are no payment methods currently associated with your account.</div>

                    )
                ) : (
                    <Circle />
                )}


                <Card>

                    <CardHeader>
                        <CardTitle className="mb-0">Card Storage Form</CardTitle>

                        <p className="text-sm mb-0">The form below can be used to add a credit or debit card to your account. Adding a card does NOT create an immediate charge on your account.</p>

                    </CardHeader>

                    <CardHeader>
                        <p className="text-sm mb-0 text-info-original font-weight-bold"><i className="fas fa-info-circle mr-2" />When adding a card number do not include any spaces or dashes.</p>
                    </CardHeader>

                    <CardBody className="pb-0">

                        {!loadedIframe ? <Circle /> : null}

                        {payformUrl? (
                            <iframe
                                id="ifrm"
                                onLoad={() => this.setState({loadedIframe: true})}
                                title="fortispay"
                                height="435px"
                                width="100%"
                                style={{border: 'none', margin: 0}}
                                src={payformUrl}
                            />
                        ) : null}

                    </CardBody>
                </Card>

            </div>

        );
    }
}

const mapStateToProps = state => {
    return {
        viewing_user: state.auth.viewing_user,
        device: state.device.info,
    };
};

export default connect(mapStateToProps, '')(PaymentMethods);
