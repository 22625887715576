import { connect } from 'react-redux';
import { CardHeader, Card, CardTitle, CardBody } from "reactstrap";

import InfoImage from '../assets/InfoImage.svg'

import A from 'components/markup/links/A'

import cardStyles from '../../_styles/cards'

const Dashboard = () => {


    return (
        <Card style={cardStyles} className={'main-card border-info'}>
            
            <CardHeader>
                <CardTitle className="mb-0">Case Info</CardTitle>
            </CardHeader>
            
            <CardHeader className="bg-secondary image-container" style={{paddingTop: 23, paddingBottom: 23}}>
                <div className="text-center">
                    <img src={InfoImage} alt="..." className="w-50" />
                </div>
            </CardHeader>

            <CardHeader>
                <p className="text-sm mb-0">We're here to make the process of filing bankruptcy as easy as possible. If you have any questions or concerns call us anytime or click the link below to access the Fairmax Law knowledge base.</p>
            </CardHeader>
            
            <CardBody>
                <A href="https://cc.summitfe.org" className="btn btn-success btn-block btn-lg">Learn More</A>
            </CardBody>
        </Card>
    )

}


const mapStateToProps = state => {
	return {
	    company: state.company.company,
	};
};

export default connect(mapStateToProps, '')(Dashboard);