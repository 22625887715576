import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

import { Container, Row, Col, Card, CardBody } from "reactstrap";

import QuestionnaireCard from '../Questionnaire/Card'
import CreditCounselingCard from '../CreditCounseling/Card'
import DocumentsCard from '../Documents/Card'
// import InfoCard from '../Info/Card'

import CompletedImage from './assets/CompletedImage.svg'

const Dashboard = ({bk_pct_documents, bk_pct_questionnaire, bk_pct_credit_counseling, company}) => {

    const completedDocuments        = bk_pct_documents === 100;
    const completedQuestionnaire    = bk_pct_questionnaire === 100;
    const completedCreditCounseling = bk_pct_credit_counseling === 100;

    return (
        <Container fluid>

            <Helmet>
                <title>{`Contacts`}</title>
                <meta name="description" content="Contacts" />
            </Helmet>

            {!completedDocuments || !completedQuestionnaire || !completedCreditCounseling ? (
                <div>
                    <h2>Needs Attention</h2>
                    <Row className="row-sm-auto"> 
                        {!completedDocuments ? (
                            <div className="col-auto mb-4" >
                                <DocumentsCard />
                            </div>
                        ) : null}
                        
                        {!completedQuestionnaire ? (
                            <div className="col-auto mb-4" >
                                <QuestionnaireCard />
                            </div>
                        ) : null}
                        
                        {!completedCreditCounseling ? (
                            <div className="col-auto mb-4">
                                <CreditCounselingCard />
                            </div>
                        ) : null}
                    </Row>
                </div>
            ) : (
                <Card className="mb-4 main-card border-success">
                    <CardBody className="p-0">
                        <Row>
                            <Col className="align-self-center border-right">
                                <div className="rounded text-center" style={{overflow: 'hidden'}}>
                                    <img src={CompletedImage} alt="..." className="w-50" />
                                </div>
                            </Col>
                            <div className="col-auto align-self-center" style={{width: 'calc(100% - 150px)'}}>
                                <div className="p-4">
                                    <p className="mb-0"><i className="fas fa-check-double mr-2 text-info " /> That's it! You're all set with finishing your online questionnaire, credit counseling course, and uploading your documents. <b className="text-dark font-weight-bold text-underline">{company.name}</b> should be in touch with you shortly.</p>
                                </div>
                            </div>
                        </Row>
                    </CardBody>
                </Card>

            )}

            {completedDocuments || completedQuestionnaire || completedCreditCounseling ? (
                <div>
                    <h2>Completed Tasks</h2>
                    <Row className="row-sm-auto">
                        {completedDocuments ? (
                            <div className="col-auto mb-4" >
                                <DocumentsCard />
                            </div>
                        ) : null}
                        
                        {completedQuestionnaire ? (
                            <div className="col-auto mb-4" >
                                <QuestionnaireCard />
                            </div>
                        ) : null}
                        
                        {completedCreditCounseling ? (
                            <div className="col-auto mb-4">
                                <CreditCounselingCard />
                            </div>
                        ) : null}
                    </Row>
                </div>
            ) : null}

            {/* <h2>Information</h2>
            <Row>
                <div className="col-auto mb-4">
                    <InfoCard />
                </div>
            </Row> */}

           
        </Container>
    )

}


const mapStateToProps = state => {
	return {
	    company: state.company.company,
        bk_pct_documents: state.system.bk_pct_documents,
        bk_pct_questionnaire: state.system.bk_pct_questionnaire,
        bk_pct_credit_counseling: state.system.bk_pct_credit_counseling,
	};
};

export default connect(mapStateToProps, '')(Dashboard);