import { Helmet } from 'react-helmet';

import { Container, Row, Col, Card, CardBody } from "reactstrap";

import CompletedImage from './assets/CompletedImage.svg'

const Dashboard = () => {

    return (
        <Container fluid>

            <Helmet>
                <title>{`Contacts`}</title>
                <meta name="description" content="Contacts" />
            </Helmet>

            <Card className="mb-4 main-card border-success">
                <CardBody className="p-0">
                    <Row>
                        <Col className="align-self-center border-right">
                            <div className="rounded text-center" style={{overflow: 'hidden'}}>
                                <img src={CompletedImage} alt="..." className="w-50" />
                            </div>
                        </Col>
                        <div className="col-auto align-self-center" style={{width: 'calc(100% - 150px)'}}>
                            <div className="p-4">
                                <p className="mb-0"><i className="fas fa-check-double mr-2 text-info " /> Welcome to the roadside portal. Any alerts on your case will show up here. For specific questions please reach out to your attorney..</p>
                            </div>
                        </div>
                    </Row>
                </CardBody>
            </Card>
           
        </Container>
    )

}

export default Dashboard