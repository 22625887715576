import Overview        from '../pages/Overview';

import Alerts           from 'components/system/Alerts';

import BillingPay       from 'components/system/Pay';
import BillingRedirect  from '../pages/Billing/Redirect';

const routes = () =>  [


    /***********************
    Alerts
    ************************/
    {
        path: "/alerts",
        component: Alerts,
    },
    /***********************
    Billing
    ************************/
    {
        path: "/billing/:_id",
        component: BillingPay,
    },

    {
        path: "/billing",
        component: BillingRedirect,
    },

    /***********************
    DASHBOARD
    ************************/
    {
        path: "/",
        component: Overview,
    },

   
]

export default routes;
