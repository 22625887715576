import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

import { Container } from "reactstrap";

import DocumentsUpload from './Upload'
import DocumentsCard from './Card'

const Dashboard = ({bk_pct_documents}) => (
    <Container fluid>

        <Helmet>
            <title>{`Documents`}</title>
            <meta name="description" content="Documents" />
        </Helmet>

        {bk_pct_documents === 100 ? (
            <DocumentsCard />
        ) : (
            <DocumentsUpload />
        )}

    </Container>
)


const mapStateToProps = state => {
	return {
	    bk_pct_documents: state.system.bk_pct_documents,
	};
};

export default connect(mapStateToProps, '')(Dashboard);