/*
Documentation

this page holds all the routes for the dashboard layout

*/

import Overview        from '../pages/Overview';

import Questionnaire    from '../pages/Questionnaire';
import CreditCounseling from '../pages/CreditCounseling';
import Documents        from '../pages/Documents';
import Info             from '../pages/Info';

import BillingPay       from 'components/system/Pay';
import BillingRedirect  from '../pages/Billing/Redirect';

import Alerts           from 'components/system/Alerts';

const routes = () =>  [


    /***********************
    Dashboard
    ************************/
    {
        path: "/questionnaire",
        component: Questionnaire,
    },
    {
        path: "/credit_counseling",
        component: CreditCounseling,
    },
   
    {
        path: "/documents",
        component: Documents,
    },
    {
        path: "/info",
        component: Info,
    },
   
    /***********************
    Alerts
    ************************/
    {
        path: "/alerts",
        component: Alerts,
    },

    /***********************
    Billing
    ************************/
    {
        path: "/billing/:_id",
        component: BillingPay,
    },
    {
        path: "/billing",
        component: BillingRedirect,
    },

    /***********************
    DASHBOARD
    ************************/
    {
        path: "/",
        component: Overview,
    },

   
]

export default routes;
