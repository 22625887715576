import { Redirect }  from 'react-router-dom';
import { connect } from 'react-redux';

const BillingRedirect = ({selected_case}) => (
    <Redirect to={`/roadside/billing/${selected_case._id}`} />
)

const mapStateToProps = state => {
	return {
        selected_case: state.system.selected_case,
	};
};

export default connect(mapStateToProps, '')(BillingRedirect);
