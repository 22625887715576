import React from "react";


class Login extends React.Component {

    componentDidMount = () => {
        // window.location.href = 'https://google.com'
    }

	render() {

		return (

            <div></div>
		);
	}
}

export default Login

