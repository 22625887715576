import { useEffect, useCallback, useState } from 'react'
import { connect } from 'react-redux';
import PropTypes from 'prop-types'

import { Modal, Row, Col } from "reactstrap";

import { toggleAlertBS } from 'store/functions/system/system';

import Circle from 'components/markup/loading/Circle';

import _integrations from '_functions/integrations';

import keys from 'keys';

import { setZapAccess } from 'store/functions/system/system'

const QuestionnaireModal = ({showModal, toggleModal, selected_case, zap_access}) => {

    const [iframeLoaded, setIframeLoaded] = useState(false);
    const [heights, setHeights] = useState({
        bodyHeight: 0,
    })

    const onMessage = useCallback(async function(message) {
        if(message && message.data && message.data.type === 'zapReportsDocuments') {
            switch (message.data.event) {

                case 'onClose':
                    toggleAlertBS('info', 'Please note, any documents linked through Zap Reports will show up on your account after they have been reviewed.')
                    return toggleModal();
                case 'onSubmit':
                    toggleAlertBS('info', 'Please note, any documents linked through Zap Reports will show up on your account after they have been reviewed.')
                    return toggleModal();

                default:
                    break;
            }
        }
    }, [toggleModal])

    const onSetAccessCode = useCallback(async() => {
        const zapContact = await _integrations.zap_reports.findOrCreate(selected_case._id)
        if(zapContact.success) {
            setZapAccess({
                access_code: zapContact.data.access_code,
                identifier: zapContact.data.identifier
            })
        } else {
            setZapAccess({
                access_code: 'zapContact.data.access_code',
                identifier: 'zapContact.data.identifier',
                err: true
            })
        }
    }, [selected_case._id])

    const onSetMaxHeight = useCallback((tryRefresh, tries = 0) => {
        const modal = document.querySelector('.modal-questionnaire .modal-dialog');
        if(modal) {
            setHeights({
                bodyHeight: modal.offsetHeight,
            })
        } else {
            if(tryRefresh) {
                if(tries > 20) return;
                setTimeout(() => {
                    onSetMaxHeight(true, tries + 1)
                }, 200)
            }
        }
    }, [])

    const onToggle = useCallback(() => {
        if(showModal) toggleAlertBS('info', 'Please note, any documents linked through Zap Reports will show up on your account after they have been reviewed.')
        toggleModal();
    }, [showModal, toggleModal])

    useEffect(() => {
        if(showModal && !zap_access.access_code) onSetAccessCode();
    }, [showModal, zap_access.access_code, onSetAccessCode])

    useEffect(() => {
        if(!showModal) {
            setIframeLoaded(false);
        } else {
            window.addEventListener("message", onMessage)
            return () => {
                window.removeEventListener("message", onMessage)

            }
        }
    }, [showModal, onMessage])

    useEffect(() => {

        window.addEventListener('resize', onSetMaxHeight);
        return () => {
            window.removeEventListener('resize', onSetMaxHeight);
        }
    }, [onSetMaxHeight, showModal])

    const url = `${keys.ZAP_REPORTS_DOCUMENTS_URL}?a=${zap_access.access_code}&i=${zap_access.identifier}`

    return (
        <Modal
            modalClassName="modal-questionnaire"
            className="modal-dialog-centered modal-full-height"
            isOpen={showModal}
            toggle={onToggle}
            size={zap_access.err ? 'md' : 'lg'}
            fullscreen={"true"}
            style={{overflow: 'hidden'}}
        >

            {iframeLoaded ? null : (
                <div className="modal-body m-header" style={{maxHeight: 40}}>
                    <Row>
                        <Col xs={7}>
                            <h5 className="modal-title align-self-center mt-2">Login To Your Accounts</h5>
                        </Col>
                        <Col xs={5} className="text-right align-self-center">
                            <button onClick={onToggle} className="btn btn-outline-warning"><i className="fas fa-arrow-left mr-2 " /> Close</button>
                        </Col>
                    </Row>
                </div>
            )}

            <div className="modal-body px-0 py-0" style={{overflow: 'hidden'}}>

                {zap_access.err ? (
                    <div className="py-3 px-4">
                        <p className="text-sm mb-0">
                            <i className="fas fa-exclamation-triangle mr-2 text-warning " /> An error occurred, please refresh your page and try again OR contact support if the issue persists.
                        </p>
                    </div>
                ) : zap_access.access_code ? (
                    <div style={{height: iframeLoaded ? heights.bodyHeight : 'auto', maxHeight: window.innerHeight}}>
                        {!iframeLoaded ? <div className="py-6"><Circle /></div>  : null}
                        <iframe 
                            id="archk-zap-forms-iframe"
                            onLoad={() => {
                                setIframeLoaded(true)
                                onSetMaxHeight(true)
                                const iframe = document.getElementById('archk-zap-forms-iframe');
                                iframe.contentWindow.postMessage({
                                    type: 'zapReportsDocuments', 
                                    event: 'onLoad',
                                    data: {
                                        domain: window.location.protocol + '//' + window.location.host
                                    }
                                }, url)
                            }}
                            
                            style={{
                                display: iframeLoaded ? 'block' : 'none',
                                width: '100%',
                                height: '100%',
                                position: 'relative',
                                
                            }}
                            title="Zap Forms"
                            src={url} 
                        />
                    </div>
                ) : (
                    <div className="py-6"><Circle /></div>
                )}

            </div>

        </Modal>

    )

}

QuestionnaireModal.propTypes = {
    toggleModal   : PropTypes.func.isRequired,
    showModal     : PropTypes.bool.isRequired,
}

const mapStateToProps = state => {
	return {
	    selected_case: state.system.selected_case,
	    zap_access: state.system.zap_access,
	};
};

export default connect(mapStateToProps, '')(QuestionnaireModal);


