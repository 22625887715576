import { connect } from 'react-redux';
import { CardHeader, Card, CardTitle, CardBody } from "reactstrap";

import DocumentsImage from '../assets/DocumentsImage.svg'
import ConfirmedImage from 'assets/img/misc/ConfirmedImage.svg'

import { Link } from 'react-router-dom';

import cardStyles from '../../_styles/cards'

const Dashboard = ({bk_pct_documents}) => {

    const finished = bk_pct_documents === 100;

    return (
        <Card style={cardStyles} className={finished ? 'main-card border-success' : 'main-card border'}>
            
            <CardHeader>
                <CardTitle className="mb-0">Documents <span className="float-right">({bk_pct_documents}%)</span></CardTitle>
            </CardHeader>
            
            <CardHeader className="bg-secondary image-container" style={finished ? {maxHeight: 145} : {paddingTop: 19, paddingBottom: 19}}>
                <div className="text-center">
                    <img src={finished ? ConfirmedImage : DocumentsImage} alt="..." className={`w-50 ${finished ? 'ml-7': ''}`} />
                </div>
            </CardHeader>

            <CardHeader>
                {finished ? (
                    <p className="text-sm mb-0">You're all set here! Your documents have been uploaded and are now under review. There is no further action that you need to take in this area of your case at this moment.</p>
                ) : (
                    <p className="text-sm mb-0">To file your bankruptcy case, we need certain documents like pay stubs, bank statements, etc. You may upload copies of your files here or use Zap Reports to link your accounts.</p>
                )}
            </CardHeader>
            
            <CardBody>
                {finished ? (
                    <button disabled={true} className="btn btn-success btn-block btn-lg"><i className="fas fa-check mr-2 " /> Finished</button>
                ) : (
                    <Link to="/bankruptcy/documents" className="btn btn-success btn-block btn-lg">Get Started</Link>
                )}
            </CardBody>
        </Card>
    )

}

const mapStateToProps = state => {
	return {
	    bk_pct_documents: state.system.bk_pct_documents,
	};
};

export default connect(mapStateToProps, '')(Dashboard);