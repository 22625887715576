/*
Documentation

Renders the single view for a case within the contact view on billing center

*/

import Dots from 'components/markup/loading/Dots';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Card, CardHeader, CardTitle, CardFooter } from 'reactstrap';
import { formatMoney } from 'utils/currency';
import _invoices from '_functions/invoices'
import moment from 'moment';

class BillingOverview extends Component {

    state = {
        total_paid: null,
    };

    getRecurringData = () => {

        const _case = this.props.case;

        if(_case.recurrings && _case.recurrings.length) {

            let next_run_date;
            let next_run_amount = 0

            _case.recurrings.forEach(r => {
                next_run_amount += r.transaction_amount
                next_run_date = r.next_run_date
            })

            return ({next_run_amount, next_run_date})

        }

        return null

    }

    render() {

        const total_paid = this.props.total_paid;
        const case_total = _invoices.calculate.totalByCase(this.props.invoices)

        const recurring_data = this.getRecurringData()

        return (

            <Card>

                <CardHeader>
                    <CardTitle className="mb-0 text-capitalize">
                        <span style={{fontWeight: 'normal'}}>Balance For Case: </span>
                        {this.props.case.name}
                    </CardTitle>
                </CardHeader>

                <div className="table-responsive rounded">
                    <table className="table">

                        <tbody>

                            {recurring_data ? (
                                <tr className="bg-secondary">
                                    <td>Next Scheduled Payment  - <b>{moment.unix(recurring_data.next_run_date).format('MM/DD/YYYY')}</b></td>
                                    <td  className="text-right">Amount: {formatMoney(recurring_data.next_run_amount)}</td>
                                </tr>
                            ) : (
                                <tr className="bg-secondary">
                                    <td>Payment Plan</td>
                                    <td  className="text-right">There Is No Payment Plan Set Up For This Case</td>
                                </tr>
                            )}

                            <tr>
                                <td>My Payments</td>
                                <td className="text-right">
                                    {total_paid === null ? (
                                        <>Loading<Dots /> </>
                                    ) : (
                                        formatMoney(total_paid)
                                    )}
                                </td>
                            </tr>

                            <tr>
                                <td>Total Billables</td>
                                <td  className="text-right">{formatMoney(case_total)}</td>
                            </tr>

                            <tr>
                                <td>Outstanding Balance</td>
                                <td  className="text-right">
                                    {total_paid === null ? (
                                        <>Loading<Dots /></>
                                    ) : (
                                        <b className={case_total - total_paid > 0 ? 'text-danger' : 'text-success'}>
                                        {formatMoney(case_total - total_paid)}
                                        </b>
                                    )}
                                </td>
                            </tr>

                        </tbody>

                    </table>
                </div>
                
                <CardFooter>
                    <p className="text-sm mb-0"><small>If you have a question about your billing call us at 888 FAIRMAX</small></p>
                </CardFooter>

            </Card>

        )

    }

}

BillingOverview.propTypes = {
    case: PropTypes.object.isRequired,
    total_paid: PropTypes.number.isRequired,
    invoices: PropTypes.array.isRequired
}

export default BillingOverview
