import { useEffect, useCallback, useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { Modal } from "reactstrap";

import { toggleAlertBS, toggleStandardLoader } from 'store/functions/system/system';

import Circle from 'components/markup/loading/Circle';

import _questionnaire from '_functions/questionnaire'
import _cases from '_functions/cases'

import { setSelectedCase } from 'store/functions/system/system'

import _integrations from '_functions/integrations';

import keys from 'keys';

import { setZapAccess } from 'store/functions/system/system'

const QuestionnaireModal = ({showModal, toggleModal, selected_case, onSubmit, zap_access}) => {

    const [iframeLoaded, setIframeLoaded] = useState(false);
    const [heights, setHeights] = useState({
        bodyHeight: 0,
    })

    const onSetAccessCode = useCallback(async() => {
        const zapContact = await _integrations.zap_reports.findOrCreate(selected_case._id)
        if(zapContact.success) {
            setZapAccess({
                access_code: zapContact.data.access_code,
                identifier: zapContact.data.identifier
            })
        } else {
            setZapAccess({
                access_code: 'zapContact.data.access_code',
                identifier: 'zapContact.data.identifier',
                err: true
            })
        }
    }, [selected_case._id])

    const onSetMaxHeight = useCallback((tryRefresh, tries = 0) => {
        const modal = document.querySelector('.modal-questionnaire .modal-dialog');
        if(modal) {
            setHeights({
                bodyHeight: modal.offsetHeight,
            })
        } else {
            if(tryRefresh) {
                if(tries > 20) return;
                setTimeout(() => {
                    onSetMaxHeight(true, tries + 1)
                }, 200)
            }
        }
    }, [])

     const onMessage = useCallback(async function(message) {
        if(message && message.data && message.data.type === 'zapReportsForms') {
            switch (message.data.event) {

                case 'onClose':
                    return toggleModal();

                case 'onSubmit': 
                    
                    toggleModal()

                    toggleStandardLoader(true)
                    const document = message.data.data.document;
                    const uploaded = await _questionnaire.submit({document, case: selected_case._id})
                    toggleStandardLoader(false);

                    if(uploaded.success) {
                        const _foundCase = await _cases.findById(selected_case._id)
                        if(_foundCase.data) {
                            onSubmit()
                            setSelectedCase(_foundCase.data)
                            return toggleAlertBS(false, 'Questionnaire Completed Successfully');
                        }
                    }
                    
                    return toggleAlertBS('info', 'Your questionnaire was submitted successfully and should appear as complete on your dashboard shortly.');
                default:
                    break;
            }
        }
    }, [selected_case._id, toggleModal, onSubmit])

    useEffect(() => {
        if(showModal && !zap_access.access_code) onSetAccessCode();
    }, [showModal, zap_access.access_code, onSetAccessCode])

    useEffect(() => {
        if(!showModal) setIframeLoaded(false)
    }, [showModal])

    useEffect(() => {
        window.addEventListener("message", onMessage)
        window.addEventListener('resize', onSetMaxHeight);
        return () => {
            window.removeEventListener("message", onMessage)
            window.removeEventListener('resize', onSetMaxHeight);
        }
    })

    const url = `${keys.ZAP_REPORTS_FORMS_URL}?a=${zap_access.access_code}&i=${zap_access.identifier}	`;

    return (
        <Modal
            modalClassName="modal-questionnaire"
            className="modal-dialog-centered modal-full-height"
            isOpen={showModal}
            toggle={toggleModal}
            size={zap_access.err ? 'md' : 'lg'}
            fullscreen={"true"}
            style={{overflow: 'hidden'}}
        >


            {iframeLoaded ? null : (
                <div className="modal-body m-header" style={{maxHeight: 40}}>
                    <h5 className="modal-title">Fairmax Questionnaire</h5>
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={toggleModal}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
            )}

            <div className="modal-body px-0 py-0" style={{overflow: 'hidden'}}>

                {zap_access.err ? (
                    <div className="py-3 px-4">
                        <p className="text-sm mb-0">
                            <i className="fas fa-exclamation-triangle mr-2 text-warning " /> An error occurred loading your online questionnaire, please refresh your page to try again OR contact support to gain access if the issue persists.
                        </p>
                    </div>
                ) : zap_access.access_code ? (
                    <div style={{height: iframeLoaded ? heights.bodyHeight : 'auto', maxHeight: window.innerHeight}}>
                        {!iframeLoaded ? <div className="py-6"><Circle /></div>  : null}
                        <iframe 
                            id="archk-zap-forms-iframe"
                            onLoad={() => {
                                setIframeLoaded(true)
                                onSetMaxHeight(true)
                                const iframe = document.getElementById('archk-zap-forms-iframe');
                                iframe.contentWindow.postMessage({
                                    type: 'zapReportsForms', 
                                    event: 'onLoad',
                                    data: {
                                        domain: window.location.protocol + '//' + window.location.host
                                    }
                                }, url)
                            }}
                            style={{
                                display: iframeLoaded ? 'block' : 'none',
                                width: '100%',
                                height: '100%',
                                position: 'relative',
                                border: 'none',
                            }}
                            title="Zap Forms"
                            src={url} 
                        />
                    </div>
                ) : (
                    <div className="py-6"><Circle /></div>
                )}

            
            </div>

        </Modal>

    )

}

QuestionnaireModal.propTypes = {
    toggleModal   : PropTypes.func.isRequired,
    showModal     : PropTypes.bool.isRequired,
}


const mapStateToProps = state => {
	return {
	    selected_case: state.system.selected_case,
	    zap_access: state.system.zap_access,
	};
};

export default connect(mapStateToProps, '')(QuestionnaireModal);
