import { connect } from 'react-redux';

import NavbarMobile from './NavbarMobile'
import NavbarDesktop from './NavbarDesktop'


const Navbar = ({showMobileNav, toggleSidenav}) => {

	// const [notifications, setNotifications] = useState(0);

	// const fetchNotifications = useCallback(async () => {
    //     const data = await _notifications.search({limit: 1, unseen: true})
    //     if(data.data) setNotifications(data.data.total_documents)
    // }, [])

    // useEffect(() => {
    //     fetchNotifications()
    // }, [fetchNotifications])

	return showMobileNav ? (
		<NavbarMobile 
			toggleSidenav={toggleSidenav} 
			// notifications={notifications}
		/>
	) : (
		<NavbarDesktop 
			// // notifications={notifications} 
		/>
	)

}

const mapStateToProps = state => {
	return {
	    device: state.device.info,
	};
};

export default connect(mapStateToProps, '')(Navbar);