import keys from 'keys';
import React from "react";
import { Helmet } from 'react-helmet';
import { Link, Redirect } from "react-router-dom";
import { CardFooter, CardHeader, CardTitle, Card, CardBody, Col, Container, Form, FormGroup, Input, Row } from "reactstrap";
import { setViewingUser } from 'store/functions/auth/auth';
import _auth from '_functions/auth';
import { getUrlParameter, setUrlParameter } from 'utils/urls';
import _companies from '_functions/companies';

import moment from 'moment';

class LoginCompanyLogin extends React.Component {

	state = {
        loaded: false,
        reset: false,
        company: {},
        email: window.location.hostname === 'localhost' ? 'john@architeck.io' : '',
        password: window.location.hostname === 'localhost' ? 'Aaaa123*' : ''
    };

	onLogin = async (e) => {

        e.preventDefault()

        this.setState({error: null})

		const { email, password, company } = this.state;

		if(!email) return this.setState({error: 'Invalid Email'});
		if(!password) return this.setState({error: 'Invalid Password'});

        //validate the users password
		const loggedIn = await _auth.login({email, password, company: company._id})

        if(loggedIn.success) {

            if(loggedIn.data.mfa_required) return this.props.onMFANeeded({
                mfa_fields: loggedIn.data.mfa_fields,
                email, 
                password, 
                company, 
            })

            // if a logged in user is not staff return
            if(!loggedIn.data.is_staff) {
                return this.setState({error: `Unauthorized`});
            }

            await setViewingUser();
            const redirect = getUrlParameter('redirect')

			this.setState({shouldRedirect: redirect ? redirect : '/cases'})

        } else {

            if(loggedIn.message && loggedIn.message[0] && loggedIn.message[0] === 'bulk attempts met') {
                return this.setState({error: `Max login attempts met, please retry after ${moment.unix(parseInt(loggedIn.message[1])).format('MM/DD/YYYY h:mm A')}`});
            }
            
            this.setState({error: loggedIn.message});

        }

    }

    componentDidMount = async () => {

        if(getUrlParameter('reset')) {
            this.setState({reset: true})
            setUrlParameter('reset', '#')
        }

        //validate the users password
		const foundCompany = await _companies.findByName(this.props.match.params.company_name);

        if(foundCompany.data) {
            this.setState({company: foundCompany.data, loaded: true}, () => {
                const input = document.getElementById('email-input');
                input.focus();
            });
        } else {
            this.setState({shouldRedirect: `/login`})
        }



    }

	render() {

        const { error, shouldRedirect, email, password, reset, loaded, company } = this.state
        const company_name = this.props.match.params.company_name;

		if(shouldRedirect) return <Redirect to={shouldRedirect} />
        if(!loaded) return <div />

		return (

		<>

			<Helmet>
				<title>Login</title>
				<meta name="description" content="Login" />
			</Helmet>

            <div className="header  py-7 py-lg-8 pt-lg-9">
                <Container>
                    <div className="header-body text-center mb-7">
                        <Row className="justify-content-center">
                            <Col className="px-5" lg="6" md="8" xl="5">

                                <img style={{width: 250}} alt="..."  src={keys.LOGO_WHITE} />
                                <p className="font-weight-bold mt-3">Account Log In</p>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>

			<Container className="mt--9 pb-5">
				<Row className="justify-content-center">
					<Col lg="5" md="7">

                        <Form role="form">

                            <Card className="bg-white border-0 mb-0 z-depth-2" >

                                <CardHeader>
                                    <CardTitle className="mb-0">{company.name} Login</CardTitle>
                                    {reset ? <p className="text-success text-sm font-weight-bold mb-0">You have successfully reset your password!</p> : null}
                                </CardHeader>

                                <CardBody >

									<FormGroup>
                                        <label className="form-control-label">Email</label>
                                        <Input
                                            id="email-input"
                                            name="email"
                                            placeholder="Email"
                                            type="email"
                                            value={email	 || ''}
                                            onChange={(e) => this.setState({email: e.target.value})}
                                            onFocus={() => this.setState({ focusedEmail: true })}
                                            onBlur={() => this.setState({ focusedEmail: false })}
                                        />
									</FormGroup>

									<FormGroup className="mt-4">
                                        <label className="form-control-label">Password</label>

                                        <Input
                                            name="password"
                                            placeholder="Password"
                                            type="password"
                                            value={password || ''}
                                            onChange={(e) => this.setState({password: e.target.value})}
                                            onFocus={() => this.setState({ focusedPassword: true }) }
                                            onBlur={() => this.setState({ focusedPassword: false })}
                                        />
									</FormGroup>

                                </CardBody>

                                <CardFooter className="text-center pt-1">
                                    {error && <p className="text-sm text-danger font-weight-bold mb--2 mt-3">{error}</p>}
                                    <button id="loginButton" onClick={this.onLogin} className="my-4 btn-block btn btn-success">
                                        Sign in
                                    </button>
                                </CardFooter>
                            </Card>

                        </Form>

						<Row className="mt-4">

							<Col xs="6" className="text-left">
								<Link to={`/forgot/${company_name}`} >
									<p className="text-sm font-weight-bold">Forgot Password?</p>
								</Link>
							</Col>
							<Col xs="6" className="text-right">
								<Link to="/register" >
                                    <p className="text-sm font-weight-bold">Register</p>
								</Link>
							</Col>

						</Row>

					</Col>
				</Row>
			</Container>
		</>
		);
	}
}

export default LoginCompanyLogin

