import apiWorker from '../apiWorker'



import calculateTotalByCase from './calculate/totalByCase'
import calculateTotalRemainingByCase from './calculate/totalRemainingByCase'
import { calculateCreditTotal, calculateInvoiceExpenses, calculateInvoiceFees, calculateInvoiceTotal } from './calculate/calculations';

const invoices = {


    calculate: {
        totalByCase             : calculateTotalByCase,
        totalRemainingByCase    : calculateTotalRemainingByCase,
        totalCredits            : calculateCreditTotal,
        totalExpenses           : calculateInvoiceExpenses,
        totalFees               : calculateInvoiceFees,
        total                   : calculateInvoiceTotal,
    },

    findByCase : (case_id) => apiWorker('get', `/api/v1/billing/invoices/case/${case_id}`),
    findByContact : () => apiWorker('get', `/api/v1/billing/invoices`),

}

export default invoices;