import { connect } from 'react-redux';
import { CardHeader, Card, CardTitle, CardBody } from "reactstrap";

import CreditCounselingGetStarted from '../assets/CreditCounselingGetStarted.svg'

import A from 'components/markup/links/A'

import cardStyles from '../../_styles/cards'

const Dashboard = ({bk_pct_credit_counseling}) => {
    
    return (
        <Card style={cardStyles} className="main-card mb-4">
            
            <CardHeader>
                <CardTitle className="mb-0">Credit Counseling <span className="float-right">({bk_pct_credit_counseling}%)</span> </CardTitle>
            </CardHeader>
            
            <CardHeader className="bg-secondary" style={{maxHeight: 142, paddingTop: 21, paddingBottom: 21}}>
                <div className="text-center">
                    <img src={CreditCounselingGetStarted} alt="..." className="w-50" />
                </div>
            </CardHeader>

            <CardHeader>
                <p className="text-sm mb-0">If you haven't already taken the course click the button below to get started. The course lasts about an hour and after your done you can upload your certificate here.</p>
            </CardHeader>
            
            <CardBody>
                <A href="https://cc.summitfe.org" className="btn btn-success btn-block btn-lg">Take The Course</A>
            </CardBody>
        </Card>
    )

}

const mapStateToProps = state => {
	return {
	    company: state.company.company,
	    bk_pct_credit_counseling: state.system.bk_pct_credit_counseling,
	};
};

export default connect(mapStateToProps, '')(Dashboard);