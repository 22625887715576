import { useCallback } from "react";

import ModalUpload from './ModalUpload'
import ModalZap from './ModalZap'

import ModalToggler from 'components/functional/modals/Toggler'
import { toggleAlertBS } from "store/functions/system/system";
import useEnhancedReducer from 'utils/useEnhancedReducer'

import Dots from 'components/markup/loading/Dots';

const Upload = ({fetchCase, index, missingDoc, missingDocuments, foundCase}) => {

    const reducer = useCallback((state, action) => {
        if(action.increment) return { uploading: state.uploading + 1 } 
        return { uploading: state.uploading - 1 }
    }, [])

    const [state, dispatch] = useEnhancedReducer(reducer, { uploading: 0 });

    const onUploadStarted = useCallback(() => {
        dispatch({increment: true})
    }, [dispatch]);

    const onUpload = useCallback(() => {
        dispatch({decrement: true})
        fetchCase()
    }, [dispatch, fetchCase])
    
    const onError = useCallback(() => {
        dispatch({decrement: true})
        toggleAlertBS('info', 'An issue occurred uploading a document, please try again.')
    }, [dispatch])

    const canLinkZap = missingDoc.name === 'Paystubs' || missingDoc.name === 'Pay Stubs' || missingDoc.name === 'Bank Statements';

    return (

        <div className="bankruptcy-upload-doc-card">

            <div className="text-right">
                {canLinkZap ? (
                    <>
                    <ModalToggler component={ModalZap} >
                        <small className="position-relative">
                            <small className="mr--4">
                                <span className="d-none d-sm-inline-block">Faster</span> {' '}
                                <span className="d-block d-sm-none" style={{position: 'absolute', top: -18, left: 10}}>Faster</span> {' '}
                                <i className="fas fa-arrow-right text-success  mr-2" style={{transform: 'rotate(45deg)', position: 'relative', top: -15, right: 5}} />
                            </small>
                        </small>
                        <button style={{minWidth: 0 }} className="btn btn-success btn-sm py-1 px-4 btn-rounded">
                            <i className="fas fa-link mr-2 " /> Connect Accounts
                        </button>
                    </ModalToggler>
                    <small className="px-1"><small>or</small></small>
                    </>
                ) : null}

                <ModalToggler 
                    component={ModalUpload} 
                    missingDoc={missingDoc} 
                    foundCase={foundCase} 
                    onUpload={onUpload} 
                    onUploadStarted={onUploadStarted}
                    onError={onError}
                >
                    <button style={{minWidth: 0 }} className={`btn btn-success btn-sm py-1 px-4 ${canLinkZap ? 'btn-info' : 'btn-success btn-rounded'}`}>
                        <i className="fas fa-upload mr-2 " /> Upload
                    </button>
                </ModalToggler>
            </div>

            <div className={`wrapper rounded bg-secondary border-info ${index === missingDocuments.length -1 ? '' : 'mb-4'}`} style={{borderSize: '3px'}}>
                <div className="p-4 border-bottom text-dark">
                    <i className="far fa-folder-open mr-2" /> {missingDoc.name}
                </div>

                {state.uploading > 0 ? (
                    <div className="px-4 py-2 border-bottom bg-secondar">
                        <p className="text-sm mb-0">Uploading {state.uploading} document{state.uploading > 1 ? 's' : ''} <Dots /></p>
                    </div>
                ) : null}

                {missingDoc.description ? (
                    <div className="px-4 py-2 border-bottom bg-secondar">
                        <p className="text-sm mb-0">{missingDoc.description}</p>
                    </div>
                ) : null}
                <div>

                {missingDoc.documents.length ? (
                    <div className="p-4">
                        {missingDoc.documents.map(d => (
                            <div className="p-3 rounded border mb-3 bg-white">{d.friendly_name}</div>
                        ))}
                    </div>
                ) : (
                    <div className="p-4">
                        <p className="text-sm mb-0">No Documents Uploaded</p>
                    </div>
                )}
                </div>
            </div>

        </div>
    )
}

export default Upload;

