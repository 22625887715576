import { useState, useCallback } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { CardHeader, Card, CardTitle, CardBody } from "reactstrap";

import ModalToggler from 'components/functional/modals/Toggler'

import QuestionnaireModal from '../Modal';

import QuestionnaireImage from '../assets/QuestionnaireImage.svg'
import ConfirmedImage from 'assets/img/misc/ConfirmedImage.svg'

import cardStyles from '../../_styles/cards'

const Dashboard = ({bk_pct_questionnaire, selected_case}) => {

    const [redirect, setRedirect] = useState(false)

    const finished = bk_pct_questionnaire === 100;

    const onSubmit = useCallback(() => {
        setRedirect('/bankruptcy')
    }, [])

    if(redirect) return <Redirect to={redirect} />

    return (
        <Card style={cardStyles} className={finished ? 'main-card border-success' : 'main-card border'}>
            
            <CardHeader>
                <CardTitle className="mb-0">Online Questionnaire <span className="float-right">({bk_pct_questionnaire}%)</span> </CardTitle>
            </CardHeader>
            
            <CardHeader className="bg-secondary image-container" style={{maxHeight: finished ? 146 : 'auto'}}>
                <div className="text-center">
                    <img src={finished ? ConfirmedImage : QuestionnaireImage} alt="..." className={`w-50 ${finished ? 'ml-7': ''}`} />
                </div>
            </CardHeader>

            <CardHeader>
                {finished ? (
                    <p className="text-sm mb-0">You're all set here! Your questionnaire has been finished and is now under review. There is no further action that you need to take in this area of your case.</p>
                ) : (
                    <p className="text-sm mb-0">Before you case can get filed we need some information about your current situation. Please use the button below to finish filling out the Fairmax Law Questionnaire</p>
                )}
            </CardHeader>
            
            <CardBody>
                {finished ? (
                    <button disabled={true} className="btn btn-success btn-block btn-lg"><i className="fas fa-check mr-2 " /> Finished</button>
                ) : (
                    <ModalToggler component={QuestionnaireModal} selected_case={selected_case} onSubmit={onSubmit}>
                        <button className="btn btn-success btn-block btn-lg">Launch Questionnaire</button>
                    </ModalToggler>
                )}
            </CardBody>
        </Card>
    )

}


const mapStateToProps = state => {
	return {
	    company: state.company.company,
	    bk_pct_questionnaire: state.system.bk_pct_questionnaire,
	    selected_case: state.system.selected_case,
	};
};

export default connect(mapStateToProps, '')(Dashboard);