import PropTypes from 'prop-types'

import { Modal } from "reactstrap";

const ModalForgotCompany = ({showModal, toggleModal}) => (
    <Modal
        className="modal-dialog-centered"
        isOpen={showModal}
        toggle={toggleModal}
        size="md"
    >

        <div className="modal-header">
            <h5 className="modal-title">Forgot Your Company</h5>
            <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={toggleModal}
            >
                <span aria-hidden={true}>×</span>
            </button>
        </div>

        <div className="modal-body">
            <p className="text-sm">Your company is the company name that was entered upon account creation</p>
            <p className="text-sm mb-0">If you do not know your company name please reach out to the person who created your account or send an email to staff@fairmaxlaw.com</p>
        </div>

        <div className="modal-footer text-right">
            <button className="btn btn-success" onClick={toggleModal}>
                Close
            </button>
        </div>

    </Modal>

)

ModalForgotCompany.propTypes = {
    toggleModal   : PropTypes.func.isRequired,
    showModal     : PropTypes.bool.isRequired,
}

export default ModalForgotCompany
