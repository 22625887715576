/*
Documentation

this page handles creating a users account

*/

import keys from 'keys';
import React from "react";
import { Helmet } from 'react-helmet';
import { Link, Redirect } from "react-router-dom";
import { Card, CardBody, CardTitle, CardHeader, CardFooter, Col, Container, Form, FormGroup, Input, Row } from "reactstrap";
import { setViewingUser } from 'store/functions/auth/auth';
import { toggleStandardLoader } from 'store/functions/system/system';
import _contacts from '_functions/contacts';
import _auth from '_functions/auth';
import { getUrlParameter, setUrlParameter } from 'utils/urls';
import _companies from '_functions/companies';

class Register extends React.Component {

	state = {
        reset: false,
        email: getUrlParameter('email') || '',
        password: '',
        company: {}
    };

    isValidating = false;

	//on keypress of enter simulate the form being submitted for better UI
	_handleKeyDown = (e) => {
		if (e.key === 'Enter') {
			document.getElementById("signUpButton").click();
		}
	}

	onSignUp = async (e) => {

        e.preventDefault();

        if(this.isValidating) return;

        this.isValidating = true;


        this.setState({
            error: false,
            emailError: false,
            passwordError: false,
            passwordConfirmError: false,
            given_nameError: false,
        })

		const { email, password, passwordConfirm } = this.state;
        let errors = 0;

        if(!email)           { errors++; this.setState({emailError: true}) }
        if(!password)        { errors++; this.setState({passwordError: true}) }
        if(!passwordConfirm) { errors++; this.setState({passwordConfirmError: true}) }
        if(!email)           { errors++; this.setState({emailError: true}) }

        if(errors) return this.isValidating = false

        toggleStandardLoader(true)

        const created = await _contacts.register({
            company: this.state.company._id,
            email, 
            password, 
            passwordConfirm, 
        })

        if(!created.success) {
            toggleStandardLoader(false)

            this.isValidating = false
            return this.setState({error: created.message})
        }

        const loggedIn = await _auth.login({email, password, company: created.data.company})

        toggleStandardLoader(false)

        this.isValidating = false

        if(loggedIn.success) {

            await setViewingUser();
            const redirect = getUrlParameter('redirect')

			this.setState({shouldRedirect: redirect ? redirect : '/cases'})

        } else {

            this.setState({error: loggedIn.message});

        }

    }

    componentDidMount = async () => {

        //validate the users password
		const foundCompany = await _companies.findByName('fairmax law');

        if(foundCompany.data) {
            this.setState({company: foundCompany.data, loaded: true}, () => {
                const input = document.getElementById('email-input');
                input.focus();
            });
        } else {
            this.setState({shouldRedirect: `/login`})
        }


        if(getUrlParameter('reset')) {
            this.setState({reset: true})
            setUrlParameter('reset', '#')
        }

        if(getUrlParameter('email')) {
            setUrlParameter('email', '')
        }

        const input = document.getElementById('register-first-name');
        if(input) input.focus();

    }

	render() {

        const { error, shouldRedirect, email, password, passwordConfirm } = this.state
		if(shouldRedirect) return <Redirect to={shouldRedirect} />

		return (

		<>

			<Helmet>
				<title>Login</title>
				<meta name="description" content="Login" />
			</Helmet>

            <div className="header  py-7 py-lg-8 pt-lg-9">
                <Container>
                    <div className="header-body text-center mb-7">
                        <Row className="justify-content-center">
                            <Col className="px-5" lg="6" md="8" xl="5">

                                <img style={{width: 250}} alt="..."  src={keys.LOGO_WHITE} />
                                <p className="font-weight-bold mt-4 pt-1 mb-0">Create Account</p>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>

			<Container className="mt--9 pb-5">
				<Row className="justify-content-center">
					<Col lg="5" md="7">

                        <Form role="form">

                            <Card className="bg-white  mb-0 z-depth-2" >

                                <CardHeader>
                                    <CardTitle className="mb-0">Register Your Account!</CardTitle>
                                    <p className="text-sm mb-0">Add the email address associated with your account below.</p>
                                </CardHeader>

                                <CardBody className="bg-secondary">

                                  
                                    <FormGroup>
                                        <label className="form-control-label">
                                            <span className={this.state.emailError ? 'text-danger' : null} >My Email*</span>
                                        </label>
                                        <Input
                                            id="email-input"
                                            name="email"
                                            onKeyDown={this._handleKeyDown}
                                            type="email"
                                            value={email || ''}
                                            onChange={(e) => this.setState({email: e.target.value})}
                                        />
                                    </FormGroup>

                                </CardBody>

                                <CardFooter className="bg-secondary">

                                    <FormGroup>
                                        <label className="form-control-label">
                                            <span className={this.state.passwordError ? 'text-danger' : null} >Password*</span>
                                        </label>
                                        <Input
                                            onKeyDown={this._handleKeyDown}
                                            type="password"
                                            value={password || ''}
                                            onChange={(e) => this.setState({password: e.target.value})}
                                        />
                                    </FormGroup>

                                    <FormGroup>
                                        <label className="form-control-label">
                                            <span className={this.state.passwordConfirmError ? 'text-danger' : null} >Confirm Password*</span>
                                        </label>
                                        <Input
                                            onKeyDown={this._handleKeyDown}
                                            type="password"
                                            value={passwordConfirm || ''}
                                            onChange={(e) => this.setState({passwordConfirm: e.target.value})}
                                        />
                                    </FormGroup>


                                </CardFooter>

                                {error && (
                                    <CardFooter>
                                        {Array.isArray(error) ? error.map((e, i) => (
                                            <p key={i} className="mb-2 text-sm text-danger font-weight-bold">*{e}</p>
                                        )) : (
                                            <p className="mb-0 text-sm text-danger font-weight-bold">{error}</p>
                                        )}

                                    </CardFooter>
                                )}

                                <CardFooter className="text-right">

                                    <button id="signUpButton" onClick={this.onSignUp} className="btn btn-success">
                                        Create My Account
                                    </button>

                                </CardFooter>
                            </Card>

                        </Form>


						<div className="mt-4 text-center">
							<Link to="/login" >
                                <p className="text-sm font-weight-bold">Login</p>
							</Link>
						</div>

					</Col>
				</Row>
			</Container>
		</>
		);
	}
}

export default Register

