import { useEffect, useState, useCallback } from "react";
import { connect } from 'react-redux';

import { Card, CardHeader, CardBody, CardTitle, Row, Col } from 'reactstrap';

import Circle from 'components/markup/loading/Circle';

import _cases from '_functions/cases';

import UploadImage from './assets/UploadImage.svg'

import MissingDoc from './MissingDoc'

const Upload = ({selected_case}) => {

    const [foundCase, setFoundCase] = useState(null);
    const [missingDocuments, setMissingDocuments] = useState([]);
    const [completedDocuments, setCompletedDocuments] = useState([]);
    
    const setDocuments = useCallback((missing_documents) => {
        const _missingDocuments = [];
        const _completedDocuments = [];

        const documents = missing_documents.filter(doc => doc.name !== 'Questionnaire' && doc.name !== 'Questionnaire ' && doc.name !== 'Credit Counseling Certificate');

        documents.forEach(doc => {
            if(!doc.status || doc.status === 'missing') {
                _missingDocuments.push(doc);
            } else {
                _completedDocuments.push(doc);
            }
        })

        setMissingDocuments(_missingDocuments)
        setCompletedDocuments(_completedDocuments)
    }, [])

    const fetchCase = useCallback(async () => {
        const _foundCase = await _cases.findById(selected_case._id)
        if(_foundCase.data) {
            setFoundCase(_foundCase.data);
            setDocuments(_foundCase.data.missing_docs)
        }

    }, [selected_case._id, setDocuments])

    useEffect(() => {
        fetchCase()
    }, [fetchCase])

    if(!foundCase) return <div className="py-6"><Circle /></div>

    return (
        <div>

            <Card className="mb-4">
                <CardBody className="p-0">
               
                    <div className="d-none d-sm-block">
                        <Row >
                            <Col className="align-self-center ">
                                <div className="rounded" style={{overflow: 'hidden'}}>
                                    <img src={UploadImage} alt="..." className="w-100" />
                                </div>
                            </Col>
                            <div className="col-auto align-self-center border-left" style={{width: 'calc(100% - 200px)'}}>
                                <div className="p-4">
                                    <p className="mb-0">Let's upload your documents! Every bankruptcy case is different and the documents you need for your case are listed below. <b><i className="fas fa-info-circle pl-2 mr-2 text-info " /> <span className="text-underline text-dark">Please note:</span> All uploaded documents are sent for review by our team before being marked as complete.</b></p>
                                </div>
                            </div>
                        </Row>
                    </div>

                    <div className="d-block d-sm-none">
                        <div className="rounded text-center p-4" style={{overflow: 'hidden'}}>
                            <img src={UploadImage} alt="..." className="w-50" />
                        </div>

                        <div className="p-4">
                            <p className="mb-0">Let's upload your documents! Every bankruptcy case is different and the documents you need for your case are listed below. <b><i className="fas fa-info-circle pl-2 mr-2 text-info " /> <span className="text-underline text-dark">Please note:</span> All uploaded documents are sent for review by our team before being marked as complete.</b></p>
                        </div>
                    </div>

                </CardBody>
            </Card>

            <Row className="row-sm-auto">
                {missingDocuments.length ? (
                    <div className="col-auto mb-4" style={{width: 363}}>
                        <Card className="bg-secondar">
                            <CardHeader>
                                <CardTitle><i className="fas fa-info-circle text-info mr-2 " /> Awaiting Completion</CardTitle>
                                <p className="mb-0 text-sm" style={{lineHeight: '16px'}}><small>To Move Your Case Along, <b className="text-underline">Upload Or Link</b> Your Accounts For The Following Documents</small></p>
                            </CardHeader>
                            <CardBody>
                                {missingDocuments.map((missingDoc, index) => (
                                    <MissingDoc 
                                        key={index}
                                        fetchCase={fetchCase}
                                        index={index}
                                        missingDoc={missingDoc}
                                        missingDocuments={missingDocuments}
                                        foundCase={foundCase}
                                    />
                                ))}
                            </CardBody>
                        </Card>
                    </div>
                ) : null}

                <div className="col-auto" style={{width: 363}}>
                    <Card>
                        <CardHeader>
                            <CardTitle><i className="fas fa-check-double mr-2 text-success " /> Completed Documents</CardTitle>
                        </CardHeader>
                        <CardBody>
                            {completedDocuments.length ? completedDocuments.map((doc, i) => (
                                <div className={`rounded  border-success bg-secondary ${i === completedDocuments.length -1 ? '' : 'mb-4'}`} style={{borderSize: '3px'}}>
                                    <div className="p-4">
                                        <i className="fas fa-check text-success mr-2" /> {doc.name}
                                    </div>
                                </div>
                            )) : (
                                <p className="mb-0">You don't have any completed documents yet.</p>
                            )}
                        </CardBody>
                    </Card>
                </div>
        
            </Row>

        </div>
    )
}


const mapStateToProps = state => {
	return {
	    selected_case: state.system.selected_case,
	};
};

export default connect(mapStateToProps, '')(Upload);

