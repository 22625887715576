import LOGO from 'assets/img/brand/LogoCut.png'

let keys = {
    LOGO,
    LOGO_WHITE: LOGO,
    COMPANY: 'Fairmax Law',
};

const HOSTNAME = window.location.hostname

//using production keys
if(HOSTNAME === 'portal.myfairmax.com') {

    keys = {

        ...keys,

        SYSTEM_API_KEY            : 'architeck-58163098451',
        MX_CLIENT_ID              : '',
        API_URL                   : 'https://portal-api.myfairmax.com',
        ZAP_REPORTS_DOCUMENTS_URL : 'https://reports.zapreports.com/fairmax_law',
        ZAP_REPORTS_FORMS_URL     : 'https://forms.zapreports.com/fairmax_law/56',
    }

//using staging keys
} else if(HOSTNAME !== 'localhost') {

    keys = {

        ...keys,

        SYSTEM_API_KEY            : 'architeck-946154718',
        MX_CLIENT_ID              : '4f7d5f10-c1fb-4d43-b034-7d8d2296e1ba',
        API_URL                   : 'https://staging.portal-api.myfairmax.com',
        ZAP_REPORTS_DOCUMENTS_URL : 'https://staging.reports.zapreports.com',
        ZAP_REPORTS_FORMS_URL     : 'https://staging.forms.zapreports.com',


    }

//using development keys
} else {

    keys = {

        ...keys,

        SYSTEM_API_KEY            : 'architeck-',
        MX_CLIENT_ID              : '4f7d5f10-c1fb-4d43-b034-7d8d2296e1ba',
        API_URL                   : 'http://localhost:5001',
        ZAP_REPORTS_DOCUMENTS_URL : 'http://localhost:3001/archk',
        ZAP_REPORTS_FORMS_URL     : 'http://localhost:3004/archk/1460',

    }

}

export default keys


