import keys from 'keys';
import React, { Component } from 'react';
import { Col, Modal, Row } from 'reactstrap';
import moment from 'moment'
import { formatMoney } from 'utils/currency';

import _invoices from '_functions/invoices';

const Item = (props) => {

    return (
        <Row className={props.gray === true ? 'bg-secondary' : null}>
            <Col xs={6}>
                <p className="text-sm mb-0">
                    <span className={props.bold === true ? 'font-weight-bold' : null}>
                        {props.name}
                    </span>
                </p>
            </Col>
            <Col xs={6}>
                <p className="text-sm mb-0 text-right">
                    <span className={props.bold === true ? 'font-weight-bold' : null}>
                        {props.value}
                    </span>
                </p>
            </Col>
        </Row>
    );

}

const ItemFee = (props) => {

    return (
        <Row className={props.gray === true ? 'bg-secondary' : null}>
            <Col xs={6}>
                <p className="text-sm mb-0">
                    <span className={props.bold === true ? 'font-weight-bold' : null}>
                        {props.name}
                    </span>
                </p>
            </Col>
            <Col xs={3}>
                <p className="text-sm mb-0 text-right">
                    <span className={props.bold === true ? 'font-weight-bold' : null}>
                        {props.descriptor}
                    </span>
                </p>
            </Col>
            <Col xs={3}>
                <p className="text-sm mb-0 text-right">
                    <span className={props.bold === true ? 'font-weight-bold' : null}>
                        {props.value}
                    </span>
                </p>
            </Col>
        </Row>
    );

}



class Preview extends Component {

    state = {
        confirmed: false
    }

    toggleModal = () => {

        this.setState({confirmed: false})
        this.props.toggle()

    }

    getDiscountText = (invoice) => {

        let d = invoice.discounts

        if(d && d.length) {

            if(d[0].type === 'percent') {
                return '-' + d[0].amount + '%'
            } else {
                return formatMoney(d[0].amount)
            }

        }

        return '-$0.00'

    }


    render() {

        const { invoice, showModal }= this.props

        const discount_text = this.getDiscountText(invoice)

        const invoiceFees = formatMoney(_invoices.calculate.totalFees(invoice));
        const invoiceExpenses = formatMoney(_invoices.calculate.totalExpenses(invoice))
        const invoiceTotal = formatMoney(_invoices.calculate.total(invoice))
        const creditTotal = formatMoney(_invoices.calculate.totalCredits(invoice))

        const payURL = invoice.case ? `${window.location.origin}/dashboard/billing/pay/${invoice.case._id}` : null

        if(!invoice) return

        return (

            <Modal
                className="modal-dialog-centered"
                isOpen={showModal}
                toggle={() => this.toggleModal()}
                size="lg"
                fade={false}
                id="modal-invoice"
            >
                <div className="modal-header">
                    <h5 className="modal-title">Invoice Details</h5>
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => this.toggleModal()}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>

                <div className="modal-body bg-secondary">

                    <div id="invoice-preview" className="p-4 bg-white" style={styles.previewContainer}>

                        <Row className="mb-4 bg-white p-4">

                            <Col xs={4}></Col>
                            <Col xs={4} className="align-self-center">
                                <img alt={keys.COMPANY_NAME} className="w-100" src={keys.LOGO} />
                            </Col>

                        </Row>


                        <Row className="mb-4">

                            <Col xs={4} className="align-self-center">
                                <p className="font-weight-bold text-sm mb-0">
                                    23756 Michigan Ave. Suite 309 <br />
                                    Dearborn, MI 48124
                                </p>
                            </Col>
                            <Col xs={4} className="align-self-center text-center">
                                <p className="font-weight-bold text-sm mb-0">
                                    T: 888-FAIRMAX<br />
                                    (888-324-7629)
                                </p>
                            </Col>
                            <Col xs={4} className="align-self-center text-right">
                                <p className="font-weight-bold text-sm mb-0">fairmaxlaw.com</p>
                            </Col>

                        </Row>

                        <Row className="mb-5">

                            <Col xs={6} className="align-self-center">
                                <p className="text-sm mb-0">
                                    Michael Jaafar<br />
                                    staff@fairmaxlaw.com
                                </p>
                            </Col>
                            <Col xs={6} className="align-self-center text-right">
                                <p className="text-sm mb-0">
                                    ID: {invoice._id} <br />
                                    <b>{moment.unix(invoice.date).format('MMM Do, YYYY')}</b>
                                </p>
                            </Col>

                        </Row>

                        <h2>Billing Summary</h2>

                        <Item
                            bold={true}
                            gray={true}
                            name="Details"
                            value=""
                        />

                        <Item
                            name={'Invoice Name: ' + invoice.name}
                            value={invoice.terms ? (
                                invoice.terms === '604800' ? 'Terms: 7 Days' :
                                invoice.terms === '1296000' ? 'Terms: 14 Days' : 'Terms: 30 Days'
                            ) : (
                                <b>No terms set</b>
                            )}
                        />

                        <Item
                            bold={true}
                            gray={true}
                            name="Fees"
                            value=""
                        />

                        <Item
                            name="Total For Services Rendered"
                            value={invoiceFees}
                        />

                        <Item
                            name="Total Expense Cost"
                            value={invoiceExpenses}
                        />

                        <Item
                            bold={true}
                            gray={true}
                            name="Credits & Discounts"
                            value=""
                        />

                        <Item
                            name="Total Credits"
                            value={'-' + creditTotal}
                        />

                        <Item
                            name="Total Discount"
                            value= {discount_text}
                        />



                        <Item
                            bold={true}
                            gray={true}
                            name="Balance Due"
                            value={invoiceTotal}
                        />

                        {payURL ? (
                            <div className="my-5">
                                <hr className="mb-3 mt-0" />
                                    <p className="text-sm mb-0">
                                        You may view your current balance and pay online by clicking the link below:
                                    </p>
                                    <p className="text-sm mb-0 font-weight-bold">
                                        <a style={{whiteSpace: 'pre-line', wordBreak: 'break-all'}} href={payURL}> {payURL}</a>
                                    </p>
                                <hr className="mt-3 mb-0" />
                            </div>
                        ) : null}

                        {invoice.fees && invoice.fees.length ? (
                            <div className="my-5">

                                <h2>Services Rendered Breakdown</h2>

                                <ItemFee
                                    bold={true}
                                    name="Description"
                                    descriptor="Hours"
                                    value="Amount"
                                />

                                {invoice.fees.map((f, i) => (
                                    f.status !== 'hidden' ? (
                                        <ItemFee
                                            key={i}
                                            name={
                                                f.description && f.description.includes('Uploaded the document') ?
                                                'Uploaded document(s)' :
                                                f.description
                                            }
                                            descriptor={f.hours}
                                            value={f.status === 'billable' ? formatMoney(f.amount) : '$0.00'}
                                        />
                                    ) : null
                                ))}

                                <ItemFee
                                    bold={true}
                                    gray={true}
                                    name="Total"
                                    descriptor=""
                                    value={invoiceFees}
                                />

                            </div>
                        ) : null}


                        {invoice.expenses && invoice.expenses.length ? (
                            <div className="my-5">

                                <h2>Expense Breakdown</h2>

                                <ItemFee
                                    bold={true}
                                    name="Description"
                                    descriptor="Cost"
                                    value="Total"
                                />

                                {invoice.expenses.map(f => (
                                    <ItemFee
                                        name={f.description}
                                        descriptor={
                                            <span>
                                                ({f.quantity}) {formatMoney(f.amount)}
                                            </span>
                                        }
                                        value={formatMoney(f.total)}
                                    />
                                ))}

                                <ItemFee
                                    bold={true}
                                    gray={true}
                                    name="Total"
                                    descriptor=""
                                    value={invoiceExpenses}
                                />

                            </div>
                        ) : null }

                        {invoice.credits && invoice.credits.length ? (
                            <div className="my-5">

                                <h2>Credit Breakdown</h2>

                                <ItemFee
                                    bold={true}
                                    name="Description"
                                    value="Amount"
                                />

                                {invoice.credits.map(f => (
                                    <ItemFee
                                        name={f.description}
                                        value={formatMoney(f.amount)}
                                    />
                                ))}

                                <ItemFee
                                    bold={true}
                                    gray={true}
                                    name="Total"
                                    value={'-' + creditTotal}
                                />

                            </div>
                        ) : null }

                        {invoice.discounts && invoice.discounts.length ? (
                            <div className="my-5">

                                <h2>Discounts</h2>

                                <ItemFee
                                    bold={true}
                                    name="Description"
                                    value="Amount"
                                />

                                {invoice.discounts.map(f => (
                                    <ItemFee
                                        name={f.description}
                                        value={discount_text}
                                    />
                                ))}

                                <ItemFee
                                    bold={true}
                                    gray={true}
                                    name="Total"
                                    value={discount_text}
                                />

                            </div>
                        ) : null }

                    </div>



                </div>
            </Modal>

        )

    }

}

const styles = {
    previewContainer: {
        maxWidth: 750,
        margin: 'auto',
        overflow: 'auto'
    }
}

export default Preview;
