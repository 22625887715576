import PropTypes from 'prop-types'

import { Modal } from "reactstrap";

import DragAndDrop from 'components/functional/uploads/DragAndDrop';

const ModalUpload = ({showModal, toggleModal, missingDoc, foundCase, onUpload, onUploadStarted, onError}) => (
    <Modal
        className="modal-dialog-centered"
        isOpen={showModal}
        toggle={toggleModal}
        size="lg"
    >

        <div className="modal-header">
            <h5 className="modal-title">Upload {missingDoc ? missingDoc.name : ''}</h5>
            <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={toggleModal}
            >
                <span aria-hidden={true}>×</span>
            </button>
        </div>

        <div className="modal-body">

            {missingDoc ? (
                <DragAndDrop
                    zone_id="archk-portal-upload"
                    url={`/v1/api/documents`}
                    onError={onError}
                    defaultValue={`${missingDoc.name} (${missingDoc.documents.length + 1})`}
                    // uploadOne={true}
                    onUploadStarted={() => {
                        // toggleModal()
                        onUploadStarted()
                    }}
                    onUpload={onUpload}
                    extraData={{
                        case: foundCase._id, 
                        missing_doc: missingDoc._id,
                    }}
                />
            ) : null}
            
        </div>

    </Modal>
)

ModalUpload.propTypes = {
    toggleModal   : PropTypes.func.isRequired,
    showModal     : PropTypes.bool.isRequired,
}

export default ModalUpload
