import { connect } from 'react-redux';
import { CardHeader, Card, CardTitle, CardBody } from "reactstrap";

import { Link } from 'react-router-dom';

import CreditCounselingImage from '../assets/CreditCounselingImage.svg'
import ConfirmedImage from 'assets/img/misc/ConfirmedImage.svg'

import cardStyles from '../../_styles/cards'

const Dashboard = ({bk_pct_credit_counseling}) => {

    const finished = bk_pct_credit_counseling === 100;
    
    return (
        <Card style={cardStyles} className={finished ? 'main-card border-success' : 'main-card border'}>
            
            <CardHeader>
                <CardTitle className="mb-0">Credit Counseling <span className="float-right">({bk_pct_credit_counseling}%)</span> </CardTitle>
            </CardHeader>
            
            <CardHeader className="bg-secondary image-container" style={{maxHeight: 142}}>
                <div className="text-center">
                    <img src={finished ? ConfirmedImage : CreditCounselingImage} alt="..." className={`w-50 ${finished ? 'ml-7': ''}`} />
                </div>
            </CardHeader>

            <CardHeader>
                {finished ? (
                    <p className="text-sm mb-0">You're all set here! Your Credit Counseling Course has been finished and your certificate uploaded. There is no further action you need to take in this area of your case.</p>
                    ) : (
                    <p className="text-sm mb-0">The Credit Counseling Course is required by law but you DON'T NEED to pass!!! All you need to do is take the course which only lasts about an hour at the link below.</p>
                )}
            </CardHeader>
            
            <CardBody>
                {finished ? (
                    <button disabled={true} className="btn btn-success btn-block btn-lg"><i className="fas fa-check mr-2"  /> Finished</button>
                ) : (
                    <Link to="/bankruptcy/credit_counseling" className="btn btn-success btn-block btn-lg">Begin Course</Link>
                )}
            </CardBody>
        </Card>
    )

}

const mapStateToProps = state => {
	return {
	    company: state.company.company,
	    bk_pct_credit_counseling: state.system.bk_pct_credit_counseling,
	};
};

export default connect(mapStateToProps, '')(Dashboard);