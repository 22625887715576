import { Card, CardBody, CardHeader, CardTitle } from 'reactstrap';

const HasNoVaults = ({onChangeNavigation}) => (
    <Card className="bg-danger">

        <CardHeader className="bg-transparent">
            <CardTitle className="text-white mb-0">Payment Alert</CardTitle>
        </CardHeader>

        <CardBody>
            <p className="text-sm text-white">You do not have any payment methods on file.</p>
            <p className="text-sm mb-0">
                <span className="text-white text-underline cursor-pointer" onClick={() => onChangeNavigation(3)}>
                    Click Here To Add One
                </span>
            </p>
        </CardBody>

    </Card>
)

export default HasNoVaults;