import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Card, CardBody, CardHeader, CardTitle, CardFooter } from 'reactstrap';

import { formatMoney } from 'utils/currency';
import Circle from 'components/markup/loading/Circle';
import moment from 'moment';

import InvoicePreview from './InvoicePreview'

class Invoices extends Component {

    state = {
        showModal: false,
        invoiceToShow: {}
    };

    render() {

        const { invoices, device } = this.props;
        const { showModal, invoiceToShow } = this.state;

        return (

            <>

            <Card>

                <CardHeader>
                    <CardTitle className="mb-0 text-capitalize">All Invoices</CardTitle>
                </CardHeader>

                {invoices ? (
                    invoices.length ? (
                        <div className="table-responsive">
                            {device.isMD ? (
                                 <table className="table">

                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Paid</th>
                                            <th>Total</th>
                                            <th className="text-right">Date Sent</th>
                                            <th className="text-right">View</th>
                                        </tr>
                                    </thead>
    
                                    <tbody>
                                        {invoices.map(i => (
                                            <tr key={i._id}>
                                                <td style={{whiteSpace: 'pre', width: 180}}>{i.name}</td>
                                                <td style={{whiteSpace: 'pre', width: 110}}>{formatMoney(i.total_paid)}</td>
                                                <td style={{whiteSpace: 'pre', width: 110}}>{formatMoney(i.total)}</td>
                                                <td style={{whiteSpace: 'pre'}} className="text-right">{ moment.unix(i.date).format('MM/DD/YYYY')}</td>
                                                <td>
                                                    <div className="text-right">
                                                        <button
                                                            onClick={() => this.setState({showModal: true, invoiceToShow: i})}
                                                            className="btn btn-success"
                                                        >
                                                            View Invoice
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
    
                                </table>
                            ) : (
                                <table className="table">

                                    <thead>
                                        <tr>
                                            <th>Info</th>
                                            <th className="text-right" style={{width: 80}}>View</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {invoices.map(i => (
                                            <tr key={i._id}>
                                                <td style={{whiteSpace: 'pre', width: 180}}>
                                                    <div className="text-sm">
                                                        <span style={{display:'inline-block', width: 60}}>Name:</span>{' '}
                                                        {i.name}
                                                    </div>
                                                    <div className="text-sm">
                                                        <span style={{display:'inline-block', width: 60}}>Sent:</span>{' '}
                                                        {moment.unix(i.date).format('MM/DD/YYYY')}
                                                    </div>
                                                    <div className={`text-sm ${i.total_paid === i.total ? 'text-success' : ''}`} >
                                                        <span style={{display:'inline-block', width: 60}}>Paid:</span>{' '}
                                                        {formatMoney(i.total_paid)}
                                                    </div>
                                                    <div className={`text-sm ${i.total_paid === i.total ? 'text-success' : ''}`}>
                                                        <span style={{display:'inline-block', width: 60}}>Total:</span>{' '}
                                                        {formatMoney(i.total)}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="text-right">
                                                        <button
                                                            style={{minWidth: 80}}
                                                            onClick={() => this.setState({showModal: true, invoiceToShow: i})}
                                                            className="btn btn-success"
                                                        >
                                                            View
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>

                                </table>
                            )}
                           
                        </div>
                    ) : (
                        <CardBody>
                            <p className="text-sm mb-0 font-weight-bold">
                                There are no current invoices for this case.
                            </p>
                        </CardBody>
                    )

                ) : (
                    <Circle />
                )}

                <CardFooter>
                    <p className="text-sm mb-0"><small>If you have a question about your billing call us at 888 FAIRMAX</small></p>
                </CardFooter>

                <InvoicePreview
                    invoice={invoiceToShow}
                    showModal={showModal}
                    toggle={() => this.setState({showModal: !showModal})}
                />

            </Card>

            </>

        )

    }

}


Invoices.propTypes = {
    invoices: PropTypes.array.isRequired,
}

const mapStateToProps = state => {
	return {
	    device: state.device.info,
	};
};

export default connect(mapStateToProps, '')(Invoices);
