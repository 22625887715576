import { useCallback, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { CardHeader, Card, CardTitle, CardBody } from "reactstrap";

import CreditCounselingUploadImage from '../assets/CreditCounselingUploadImage.svg'

import cardStyles from '../../_styles/cards'

import ModalUpload from './ModalUpload';
import ModalToggler from 'components/functional/modals/Toggler'

import { toggleStandardLoader, toggleAlertBS } from 'store/functions/system/system';
import { setSelectedCase } from 'store/functions/system/system'

import _cases from '_functions/cases';


const Dashboard = ({selected_case}) => {

    const [redirect, setRedirect] = useState(false);

    const missingDoc = selected_case.missing_docs.find(f => f.name === 'Credit Counseling Certificate');

    const onUploadStarted = useCallback(() => {
        toggleStandardLoader(true);
    }, []);

    const onUpload = useCallback(async () => {
        const _foundCase = await _cases.findById(selected_case._id)
        if(_foundCase.data) {
            setSelectedCase(_foundCase.data)
            setRedirect('/bankruptcy/home')
        }

        toggleStandardLoader(false);
        toggleAlertBS(false, 'Certificate Uploaded Successfully!')
    }, [selected_case._id])
    
    const onError = useCallback(() => {
        toggleStandardLoader(false);
        toggleAlertBS('info', 'Something Went Wrong, Please Try Again')
    }, [])

    if(redirect) return <Redirect to={redirect} />
    
    return (
        <Card style={cardStyles} className="main-card mb-4">
            
            <CardHeader>
                <CardTitle className="mb-0">Upload Certificate </CardTitle>
            </CardHeader>
            
            <CardHeader className="bg-secondary" style={{paddingTop: 28, paddingBottom: 28}}>
                <div className="text-center">
                    <img src={CreditCounselingUploadImage} alt="..." className="w-50" />
                </div>
            </CardHeader>

            <CardHeader>
                <p className="text-sm mb-0">Once you have completed the course click the button below to upload your credit counseling certificate. You should get this upon completion of the course with Summit Financial.</p>
            </CardHeader>
            
            <CardBody>
                <ModalToggler 
                    component={ModalUpload}
                    missingDoc={missingDoc}
                    foundCase={selected_case}
                    onUpload={onUpload}
                    onUploadStarted={onUploadStarted}
                    onError={onError}
                >
                    <button className="btn btn-info btn-block btn-lg"><i className="fas fa-upload mr-2 " /> Upload Certificate</button>
                </ModalToggler>
            </CardBody>

        </Card>
    )

}

const mapStateToProps = state => {
	return {
	    selected_case: state.system.selected_case,
	};
};

export default connect(mapStateToProps, '')(Dashboard);