

import apiWorker from '../apiWorker'

const integrations = {
    zap_reports: {
        findOrCreate : (case_id) => apiWorker('get', `/v1/integrations/zap_reports/access_code/${case_id}`),
    }
}

export default integrations;