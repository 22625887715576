import React from 'react';
import { connect } from 'react-redux';
import { setSelectedCase } from 'store/functions/system/system'

import { Container, Row, Col, Card, CardHeader, CardTitle, CardBody, CardFooter } from 'reactstrap';

import moment from 'moment';

import BankruptcyImage from './assets/BankruptcyImage.svg'
import RoadsideImage from './assets/RoadsideImage.svg'

const SelectCase = ({cases, viewing_user}) => {

    return (
        <Container className="py-4">
            <h2 className="text-capitalize">{viewing_user.given_name ? `Hi ${viewing_user.given_name}, Welcome Back!` : 'Welcome To Fairmax Law'}</h2>

            {cases && cases.length ? (
                <div>
                    
                    <p className="font-weight-bold">
                        Please Select The Case You Wish To View Below <i className="fas fa-arrow-down " />
                    </p>

                    <Row>
                        {cases.map(_case => {

                            const isBankruptcy = _case.type === 'Bankruptcy';
                            const isRoadside = _case.type === 'Roadside';

                            if(!isBankruptcy && !isRoadside) return <React.Fragment key={_case._id}></React.Fragment>

                            return (
                                <Col key={_case._id} md={4} className="mb-4">
                                    <Card className="text-center cursor-pointer" onClick={() => setSelectedCase(_case)}>
                                        
                                        <CardHeader>
                                            <CardTitle className="mb-0">
                                                {_case.type}
                                                {isBankruptcy ? (
                                                    <small> 
                                                        {' - '}
                                                        {_case.filed_on ? 'Filed' : _case.sent_for_prep_on ? 'Attorney Review' : 'Documents Needed'} 
                                                    
                                                    </small>
                                                ) : isRoadside && _case.custom_data && _case.custom_data['Citation or Case Number 1'] ? (
                                                    <small> - {_case.custom_data['Citation or Case Number 1']}</small>
                                                ) : null}
                                            </CardTitle>
                                        </CardHeader>
        
                                        <CardBody className="bg-secondary" style={{height: 175}} >
                                            <div className="text-center">
                                                <img 
                                                    src={isBankruptcy ? BankruptcyImage : isRoadside ? RoadsideImage : null} 
                                                    alt="..." className="w-50" 
                                                />
                                            </div>
                                        </CardBody>
        
                                        <CardFooter>
                                            <p className="text-sm mb-0">
                                                <small>
                                                    Created: {moment.unix(_case.created_at).format('M/D/YYYY')}
                                                    {_case.case_number ? ' - #' + _case.case_number : ''} 
                                                </small>
                                            </p>
                                        </CardFooter>
                                        
                                    </Card>
                                </Col>
                            )
                        })}
                    </Row>
                </div>
                
            ) : (
                <p>You currently do not have any open cases with Fairmax Law. If you believe this is a mistake please reach out to us.</p>
            )}
        </Container>
    )
}


const mapStateToProps = state => {
	return {
	    cases: state.load.cases,
	    selected_case: state.system.selected_case,
	    viewing_user: state.auth.viewing_user,
	};
};

export default connect(mapStateToProps, '')(SelectCase);

