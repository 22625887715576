import Banned from '../pages/Banned';

const routes = [
    {
        path: "/banned",
        component: Banned,
    },

]

export default routes;