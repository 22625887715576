import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

import { Container, Row } from "reactstrap";

import CreditCounselingGetStarted from './GetStarted'
import CreditCounselingUpload from './Upload'
import CreditCounselingCard from './Card'

const Dashboard = ({bk_pct_credit_counseling}) => (
    <Container fluid>

        <Helmet>
            <title>{`Questionnaire`}</title>
            <meta name="description" content="Questionnaire" />
        </Helmet>

        {bk_pct_credit_counseling === 100 ? (
            <CreditCounselingCard />
        ) : (
            <Row className="row-sm-auto">
                <div className="col-auto">
                    <CreditCounselingGetStarted />
                </div>
                <div className="col-auto">
                    <div className="text-center d-none d-md-block" style={{paddingTop: 175}}>
                        <i className="fas fa-arrow-right text-warning" /> 
                    </div>
                </div>
                <div className="col-auto">
                    <CreditCounselingUpload />
                </div>
            </Row>
        )}

    </Container>
)

const mapStateToProps = state => {
	return {
        bk_pct_credit_counseling: state.system.bk_pct_credit_counseling,
	};
};

export default connect(mapStateToProps, '')(Dashboard);