const totalRemainingByCase = (invoices) => {

    let total = 0;

    if(invoices && invoices.length) {

        invoices.forEach(i => {
            if(i.status === 'sent') {
                total = total + parseFloat(i.total) - parseFloat(i.total_paid)
            }
        })

    }

    return total.toFixed(2)

}

export default totalRemainingByCase;