
import { Card, CardFooter, CardHeader, CardTitle, Col, Container, Row } from 'reactstrap';

import Logo from 'assets/img/brand/ZapReportsLogo.png';

const Banned = () => (
    <Container className="pb-5">
        <Row className="justify-content-center">
            <Col lg="5" md="7">

                <div className="text-center my-5">
                    <img src={Logo} alt="Fairmax Law" style={{maxWidth: 200}} />
                </div>

                <Card className="bg-white border-0  position-relative" >

                    <CardHeader>
                        <CardTitle className="mb-0"><i className="fas fa-info-circle text-info " /> Account Locked</CardTitle>
                    </CardHeader>

                    <CardFooter >
                        <p className="mb-0">Your account has been paused due to inactivity. Please send an email to john@zapreports.com if you wish to continue using this portal</p>
                    </CardFooter>
                   
                </Card>

            </Col>
        </Row>
    </Container>
)

export default Banned