
import { Helmet } from 'react-helmet';

import { Container } from "reactstrap";

import CreditCounselingCard from './Card'

const Dashboard = () => (
    <Container fluid>

        <Helmet>
            <title>{`Questionnaire`}</title>
            <meta name="description" content="Questionnaire" />
        </Helmet>

        <CreditCounselingCard />

    </Container>
)

export default Dashboard;